.btn-xs {
    padding: .35rem .4rem .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}
/*.btn {
    padding: .35rem .4rem .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}*/

.switch.btn { min-width: 3.7rem; min-height: calc(1.5em + .75rem + 2px); }
.switch-on.btn { padding-right: 1.5rem; }
.switch-off.btn { padding-left: 1.5rem; }

.switch.btn-lg { min-width: 5rem; line-height: 1.5; min-height: calc(1.5em + 1rem + 2px); }
.switch-on.btn-lg { padding-right: 2rem; }
.switch-off.btn-lg { padding-left: 2rem; }
.switch-handle.btn-lg { width: 2.5rem; }

.switch.btn-sm { min-width: 3.25rem; min-height: calc(1.5em + .5rem + 2px); }
.switch-on.btn-sm { padding-right: 1rem; }
.switch-off.btn-sm { padding-left: 1rem; }

/* `xs` (bootstrap-3.3 - .btn - h:22px) */
.switch.btn-xs { min-width: 3.125rem; min-height: 1.375rem; }
.switch-on.btn-xs { padding-right: .8rem; }
.switch-off.btn-xs { padding-left: .8rem; }